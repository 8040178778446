<!-- abandonDeviceChartShow图表展示 -->
<template>
	<div class="chartShow">
		<div class="chartShow-T">
			<div class="chartShow-TL">
				<Breadcrumb class="bre"></Breadcrumb>
				<div class="chartShow-searchs">
					<inputs @inputs="inputserValue" @selects="selectsValue" :inputShow="true" :selectShow='true'
						inputPlace0="请输入名称" inputPlace="请输入SN/设备名称" :options="options"></inputs>
					<div class="chartShow-tit-mai1-btn">
						<el-date-picker v-model="TimeValue" type="datetimerange" range-separator="至" :clearable="false"
							start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
							@change="Timechange">
						</el-date-picker>

						<!-- <el-button type="danger" icon="el-icon-search">查询</el-button> -->
					</div>
				</div> 
			</div>
			<div class="chartShow-TR">
				<div class="chartShow-searchsMai">
					<el-button type="primary" size="small" @click="canvasIt">截图</el-button>
					<el-button type="primary" size="small" v-permission="'AbandonDeviceChartShow:PDF'" @click="downloadPDF">下载PDF</el-button> <!-- v-permission="'chartShow:PDF'" -->

					<el-button type="primary" size="small" @click="showHighchart_h = !showHighchart_h">{{showHighchart_h?'隐藏':'显示'}}湿度</el-button>
				</div>
			</div>
		</div>
		<div class="chartShow-B">
			<div class="chartShow-B-nav">
				<div class="chartShow-B-nav1">
					<div class="chartShow-B-nav1-L">
						<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
							全选</el-checkbox>
						<div class="chartShow-B-nav1-L1" @click="theChange">反选</div>
					</div>
					<div class="chartShow-B-nav1-R">
					</div>
				</div>
				<div class="chartShow-B-nav2">
					<div class="chartShow-B-nav2-mai">
						<el-checkbox-group v-model="checkedCities">
							<el-checkbox v-for="(item,index) in cities" :label="item" :key="index" @change="handleCheckedCitiesChange(item,$event)">
								<div class="chartShow-B-nav2-t">
									<div class="chartShow-B-nav2-tName" :title="item.T_name">
										{{item.T_name}}
									</div>
									<div class="chartShow-B-nav2-tSn">
										{{item.T_sn}}[{{item.T_id}}]
									</div>
								</div>
							</el-checkbox>
						</el-checkbox-group>
					</div>
				</div>
				<div class="chartShow-B-nav3">
					<div>
						总量：{{total}}
					</div>
					<div style="text-align: center;">页码：{{pages.page}}/{{Page_size}}</div>
				</div>
			</div>
			<div class="chartShow-B-table">
				<div class="chartShow-B-table2-mai" ref="imageTofile">  <!--  v-show="optionsData.length!=0" -->
					<div style="color: #000;font-size: 20px;text-align: center;margin-top: 10px;">{{ showFirm }}</div>
					<div class="chartShow-B-table1">
						{{pages1.Time_start}}
						<div style="padding: 0 10px;">~</div>
						{{pages1.Time_end}}
					</div>
					<div class="chartShow-B-table2" :style="{height:!showHighchart_h?'75vh':'500px'}" style="position: relative;">
						<highchartspirT :option="optionsData" ref="childMethod1" :stale="stale"></highchartspirT>
						<div v-if="ifHtmlCanvas" style="height:60px;background: #fff;position: absolute;bottom: 30px;width: 98%;z-index: 9999999 !important;"></div>
					</div>					
					<div class="chartShow-B-table2" style="position: relative;">
						<!-- :style="{opacity:showHighchart_h?1:0}" -->
						<highchartspirH :option="optionsData" ref="childMethod2" :stale="stale" :style="{height:showHighchart_h?'500px':'0'}"></highchartspirH>
						<div v-if="ifHtmlCanvas" style="height:60px;background: #fff;position: absolute;bottom: 30px;width: 98%;z-index: 9999999 !important;"></div>
					</div>
				</div>
				<!--  -->
				<!-- <div class="chartShow-B-table2-mai" v-show="optionsData.length==0"
					style="color: #DCDFE6;font-size: 20px;min-height: 500px;display: flex;align-items: center;justify-content: center;">
					暂无可展示数据，更换其他设备或时间段再试试
				</div> -->
			</div>
		</div>
		<el-dialog title="下载PDF" :append-to-body="true" :visible.sync="dialogVisible" width="30%">
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item label="发货单位">
					<el-input v-model="form.T_forwarding_unit" maxlength="34" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="收货单位">
					<el-input v-model="form.T_consignee_unit" maxlength="34" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="下载模板">
					<el-radio-group v-model="form.T_template">
						<el-radio label="0">仓库</el-radio>
						<el-radio label="1">车/箱</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="form.T_remark" type="textarea" :autosize="{ minRows: 5,maxRows:9}" maxlength="16" show-word-limit></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">立即下载</el-button>
					<el-button @click="dialogVisible=false">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import html2canvas from "html2canvas";
	import {
		DevSenList,
		DataList,
		ClassList,
		DataChartPdf
	} from '../../api/dataDisplay/chartShow.js'
	import {
		formatDate
	} from '../../utils/Times.js'
	
	import {allCancelFun} from "@/plugins/calculateData"
	import Axios from 'axios'
	import { async } from "q";
	export default {
		data() {
			return {
				ifHtmlCanvas:false,
				showFirm:'',
				form:{
					T_forwarding_unit:'',
					T_consignee_unit:'',
					T_template:'0',
					T_remark:'',
				},
				dialogVisible:false,
				showHighchart_h:false,
				condition:3,
				b:1,
				stale:false,//全取消
				total:0,
				Page_size: 0,
				optionsTh:{},
				optionsData:{
					sn:null,
					id:null,
					data:[]
				},//数据包
				options: [],
				chartOptions: {},
				TimeValue: [
					// '2023-04-20 00:00:00', '2023-04-21 00:20:00'
					new Date(new Date().toLocaleDateString()).getTime(), new Date()
				],
				pages: {
					T_name: '',
					T_calssid: '',
					page: 1,
					page_z: 20,
                    T_abandon:1
				},
				pages1: { //表格数据
					T_snid: '',
					Time_start: '',
					Time_end: '',
					page: 1,
					page_z: 99999,
				},
				checkAll: false,
				checkedCities: [], //已经选择的
				cities: [],
				isIndeterminate: false,
				dialogVisible: false,
				loading:null,
				pickerOptions: {
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(new Date(new Date().toLocaleDateString()).getTime());
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '近3天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 86400000 * 2);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '近5天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 86400000 * 4);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '近7天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 86400000 * 6);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
			}
		},
		computed: {
			searchso() {
				return this.$store.state.searchs
			}
		},
		mounted() {
			
			this.GetClassListApi()
			this.GetDevSenListApi()
			this.$nextTick(()=>{
				let scrollTargetBox = document.querySelector('.chartShow-B-nav2')
				scrollTargetBox.onscroll =()=>{
					if(this.cities.length==0) return
					var scrollHeight = scrollTargetBox.scrollHeight;//251
                    var scrollTop = Math.ceil(scrollTargetBox.scrollTop);//0-18
                    var clientHeight = scrollTargetBox.clientHeight;//233
					////console.log('触发',scrollHeight ,clientHeight,this.pages.page , this.Page_size,scrollTop,scrollHeight - clientHeight == scrollTop)
					if(scrollHeight - clientHeight == scrollTop){
						if (this.pages.page >= this.Page_size) return
						this.pages.page = this.pages.page+1
						this.GetDevSenListApi()
					}
				}
				
			})
			this.showFirm = this.$store.state.showFirm.T_name
		},
		methods: {
			onSubmit(){
				this.ifHtmlCanvas = true
				this.loading = this.$loading({
					lock: true,
					text: '请稍后...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				setTimeout(async () => {
					const url = await this.html2canvasFun()
					DataChartPdf({
						T_snid:this.joinFun(),
						Time_start:this.pages1.Time_start,
						Time_end:this.pages1.Time_end,
						file:url.split(',')[1],
						T_temp_show:1,
						T_Humidity_show:this.showHighchart_h?1:0,
						...this.form
					}).then(res => {
						var arr1 = res.data.Data.split('/')
						this.loading.close()
						this.dialogVisible = false
						Axios({
							method: 'get',
							url: res.data.Data,
							responseType: 'blob'
						}).then(resBlob => {
							const FileSaver = require('file-saver')
							const blob = new Blob([resBlob.data], {
								type: 'application/pdf;charset=utf-8'
							}) // 此处type根据你想要的
							FileSaver.saveAs(blob, arr1[arr1.length-1]) // 下载的name文件名
						});
					})
					this.ifHtmlCanvas = false
				}, 100);
			},
			async downloadPDF(){
				if (this.checkedCities.length==0) {
					this.$message.error('请选择设备') 
					return;
				}else{
					this.dialogVisible = true
				}
				
			},
			joinFun(){
				const arr = [...this.checkedCities]
				const joinArr = []
				arr.forEach(item=>{
					joinArr.push(item.T_sn + ',' + item.T_id)
				})
				return joinArr.join('|') + '|'
			},
			canvasIt() {//截图
				this.ifHtmlCanvas = true
				setTimeout(async () => {
					const url = await this.html2canvasFun()
					var a = document.createElement('a')
					a.download = new Date().getTime()
					a.href = url;// 设置图片地址
					a.click();
					this.ifHtmlCanvas = false
				}, 100);
				
			},
			html2canvasFun(){
				return new Promise(resolve=>{
					html2canvas(this.$refs.imageTofile, {
						backgroundColor:' #fff',
					}).then((canvas) => {
						resolve(canvas.toDataURL("image/png"))
					});
				})
			},
			//左侧导航菜单
			async GetDevSenListApi() {
				var that = this
				var resIt = await this.GetDevSenPam()
				if (resIt.data.Code === 200) {
					////console.log('导航----', resIt)
					this.total = resIt.data.Data.Num
					this.Page_size = resIt.data.Data.Page_size
					var Arr = resIt.data.Data.DeviceSensor_lite || []
					if (Arr != null) {
						that.cities = [...that.cities,...Arr]
					}else{
						that.cities = []
					}
					////console.log('改变导航',that.cities)
				}
			},
			GetDevSenPam() {
				return new Promise(resolve => {
					DevSenList(this.pages).then(function(res) {
						resolve(res);
					})
				});
			},
			//select列表
			GetClassListApi() {
				var that = this
				ClassList({}).then(res => {
					if (res.data.Code === 200 && res.data.Msg === 'ok!') {
						var Arr = res.data.Data.Data || []
						Arr.forEach(function(item) {
							var obj = {
								value: '',
								label: ''
							}
							obj.value = item.Id
							obj.label = item.T_name
							that.options.push(obj)
						})
						that.options.unshift({
							value: '',
							label: '所有分类'
						})
					}
				})
			},
			async Timechange(e) {
				console.log('勾选',e)
				this.checkAll = false
				this.isIndeterminate = false

				// 
				this.checkedCities = []
				this.stale = true
				if (e == null) {
					this.pages1.Time_start = ''
					this.pages1.Time_end = ''
				} else {
					this.pages1.Time_start = formatDate(e[0])
					this.pages1.Time_end = formatDate(e[1])
				}
				this.pages1.page = 1
				
			},
			inputserValue(e) {
				this.cities = []
				this.pages.T_name = e
				this.pages.page = 1
				this.stale = true
				this.GetDevSenListApi()
			},
			selectsValue(e) {
				this.stale = true
				this.cities = []
				this.pages.T_calssid = e
				this.pages.page = 1
				this.GetDevSenListApi()
				
			},
			
			GetDataListApi() {//获取选择的sn数据
				return new Promise(resolve=>{
					setTimeout(() => {
						DataList(this.pages1).then(res => {
						////console.log('接口返回',res)
						var arr = res.data.Data.Data
						if ( arr === null) {
							arr = []
						} else {
							arr = arr.reverse()
						}
						resolve(arr)
					})
					},200)
				}) 
			},
			async handleCheckAllChange(val) { //全选
				this.isIndeterminate = false;
				this.pages1.Time_start = formatDate(this.TimeValue[0])
				this.pages1.Time_end = formatDate(this.TimeValue[1])
				if(val){//全选
					this.stale = false
					this.checkedCities = this.cities
					this.loading = this.$loading({
						lock: true,
						text: '请稍后...',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					let sum = 0
					
					for (let i = 0; i < this.checkedCities.length; i++) {
						this.pages1.T_snid = this.checkedCities[i].T_sn + ','+  this.checkedCities[i].T_id + "|"
						let resIt = await this.GetDataListApi()
						sum += 1
						this.optionsData.data = resIt
						this.optionsData.sn =  this.checkedCities[i].T_sn
						this.optionsData.id =  this.checkedCities[i].T_id
						this.optionsData.name =  this.checkedCities[i].T_name
						if(sum==this.checkedCities.length){
							this.loading.close()
						}
					}
					
				}else{//全取消
					////console.log('全取消',this.checkedCities)
					this.stale = true
					this.checkedCities = []
					// this.qingkong()	
				}
			},
			

			async handleCheckedCitiesChange(value,e) { //单选
				////console.log('单选',value,e,this.checkedCities)
				let checkedCount = this.checkedCities.length;
				this.checkAll = checkedCount === this.cities.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
				this.pages1.Time_start = formatDate(this.TimeValue[0])
				this.pages1.Time_end = formatDate(this.TimeValue[1])	
				this.stale = false			
				if(e){//勾选
					this.loading = this.$loading({
						lock: true,
						text: '请稍后...',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					this.pages1.T_snid = value.T_sn + ','+ value.T_id + "|"
					let resIt = await this.GetDataListApi()
					////console.log('单选返回1',resIt)
					this.optionsData.data = resIt
					////console.log('单选返回2',this.optionsData)
					this.optionsData.sn = value.T_sn
					this.optionsData.id = value.T_id
					this.optionsData.name = value.T_name
					this.loading.close()
				}else{//取消勾选
					this.optionsData.data = []
					this.optionsData.sn = value.T_sn
					this.optionsData.id = value.T_id
					this.optionsData.name = value.T_name
				}
				////console.log('r',this.optionsData)
			},
			async theChange() { //反选
				////console.log('反选1',this.cities,this.checkedCities)
				let cities = this.cities;//当前导航数量
				let checkedCities = this.checkedCities; //已经选择
				this.pages1.Time_start = formatDate(this.TimeValue[0])
				this.pages1.Time_end = formatDate(this.TimeValue[1])
				
				if (checkedCities.length === 0) {//未选择状态 去全选
					////console.log('判断0',this.checkedCities)
					this.checkedCities = cities;
					this.checkAll = true;
					this.stale = false
					this.loading = this.$loading({
						lock: true,
						text: '请稍后...',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					let sum = 0
					
					for (let i = 0; i < this.checkedCities.length; i++) {
						this.pages1.T_snid = this.checkedCities[i].T_sn + ','+  this.checkedCities[i].T_id + "|"
						let resIt = await this.GetDataListApi()
						sum += 1
						this.optionsData.data = resIt
						this.optionsData.sn =  this.checkedCities[i].T_sn
						this.optionsData.id =  this.checkedCities[i].T_id
						this.optionsData.name =  this.checkedCities[i].T_name
					}
					if(sum==this.checkedCities.length){
							this.loading.close()
					}
				} else if (checkedCities.length === cities.length) {//全选状态  全不选
					this.stale = true  //相当于全取消
					this.checkedCities = [];
					this.checkAll = false;
				} else {
					this.$refs["childMethod1"].list = []
					this.$refs["childMethod2"].list = []
					// return
					////console.log('判断2',this.checkedCities)
					let list = cities.concat(checkedCities).filter((v, i, array) => {
						return array.indexOf(v) === array.lastIndexOf(v);
					});
					////console.log('反选',list)
					this.checkedCities = list;
					this.loading = this.$loading({
						lock: true,
						text: '请稍后...',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					for (let i = 0; i < this.checkedCities.length; i++) {
						this.pages1.T_snid = this.checkedCities[i].T_sn + ','+  this.checkedCities[i].T_id + "|"
						let resIt = await this.GetDataListApi()
						this.optionsData.data = resIt
						this.optionsData.sn =  this.checkedCities[i].T_sn
						this.optionsData.id =  this.checkedCities[i].T_id
						this.optionsData.name =  this.checkedCities[i].T_name
					}
					this.loading.close()
				}
			},
		},
		
		watch: {
			searchso(newVal) {
				this.dialogVisible = newVal
			},
			cities(newVal) {
				////console.log('----------------报错1')
				if (newVal.length == 0) {
					this.tableData = []
					this.checkedCities = []
					this.pages1.T_snid = ""
				}
				////console.log('----------------报错2')
			},
			// checkedCities(newVal) {
			// 	////console.log('勾选了-----',newVal)
			// 	var that = this
			// 	if (newVal.length != 0) {
			// 		this.checkedCities.forEach(async (obj)=> {
			// 			that.pages1.T_snid = obj.T_sn + ',' + obj.T_id + '|'
			// 			that.pages1.Time_start = formatDate(that.TimeValue[0])
			// 			that.pages1.Time_end = formatDate(that.TimeValue[1])
			// 			let resIt = await that.GetDataListApi()
			// 			////console.log('监听返回',resIt)
			// 		})
			// 		// ////console.log('已勾选',arrObj.join('|') + '|')
			// 		return
			// 		this.pages1.T_snid = arrObj.join('|') + '|'
					
			// 	} else {
			// 		this.pages1.T_snid = ''
			// 		this.optionsTh = []
			// 	}

			// },
		},
	}
</script>

<style lang="scss">
	@import '../../assets/css/dataDisplay/chartShow.scss';
</style>
<style lang="scss" scoped>
	.el-checkbox {
		display: flex;
		align-items: center;
		flex: 1;
		margin-right: 0;
	}
</style>

<style>
</style>
